.myrating {

    input[type="radio"] + label:before {
        content: "";
        /* create custom radiobutton appearance */
        display: none;
        width: 20px;
        height: 20px;
        padding: 3px;
        margin-right: 5px;
        /* background-color only for content */
        background-clip: content-box;
        border: 1px solid #bbbbbb;
        background-color: #e7e6e7;
        border-radius: 50%;
      }
      
}
